import React, { useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { navigate, Link } from "gatsby"
import {
  containerBackground,
  containerText,
  primaryTitleColor,
} from "../style/theme"
import { setLanguage } from "../redux/actions/languageActions"
import { setOpen } from "../redux/actions/menuActions"
import { connect } from "react-redux"
import au from "../images/flags/australia-flag-square-xs@2x.png"
import ca from "../images/flags/canada-flag-square-xs@2x.png"
import nz from "../images/flags/new-zealand-flag-square-xs@2x.png"
import gb from "../images/flags/united-kingdom-flag-square-xs@2x.png"
import us from "../images/flags/united-states-of-america-flag-square-xs@2x.png"
import se from "../images/flags/sweden-flag-square-xs@2x.png"
import locales from "../i18n/constants"

const SectionContainer = styled.section`
  width: 100%;
  height: 100%;
  background: ${containerBackground};
  min-height: 60vh;
  position: relative;

  * {
    box-sizing: border-box;
  }
`

const SectionContainerInner = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`

const SectionTitle = styled.div`
  width: 100%;
  text-align: center;
`

const CountryWrapper = styled.div``
const Title = styled.h1`
  font-family: Poppins;
  font-size: 2.5rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${primaryTitleColor};

  @media (max-width: 992px) {
    font-size: 2rem;
  }
`

const CountryContainer = styled.div`
  height: 100%;
  max-width: 1140px;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row !important;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
  @media (max-width: 426px) {
    width: 100%;
    flex-direction: column !important;
  }
`
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 0 10px;

  @media (max-width: 426px) {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem 0;

    &:after {
      content: "";
      width: 100%;
      border-bottom: 1px solid #fafafa;
      position: absolute;
      left: 0;
      bottom: 0;
      color: #fcd62d;
    }
  }
`
const CountryFlagContainer = styled(Link)`
  height: 95%;
  width: 150px; /* 150px */
  flex: 1 100% 100%;

  flex-direction: column;

  @media (max-width: 1450px) {
    width: 10.5vw;
  }

  @media (max-width: 992px) {
    width: 27vw;
  }

  @media (max-width: 768px) {
    width: 30vw;
  }

  @media (max-width: 426px) {
    width: 20vw;
  }

  ${props =>
    props.isGray &&
    `
    opacity: 0.7;
    filter: grayscale(1);
 `}
`
const CountryFlag = styled.img`
  width: 100%;
  cursor: pointer;
  object-fit: contain;
  transition: 400ms;

  :hover {
    transform: scale(1.1);
  }
`
const CountryNameContainer = styled.div`
  flex: 1 100% 100%;
  font-family: Poppins;
  line-height: 2.5rem;
  text-align: center;
  @media (max-width: 426px) {
    margin-left: 1rem;
  }
`
const CountryName = styled.span`
  color: ${containerText};
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
`

/**
 *
 * Language picker used in the index page
 * Note that the id from child components need to match the keys in locales
 *
 * @param {Function} setLanguage redux function used to activte language
 * @param {Function} setOpen redux function used to set state on menu
 * @param {Object} menu redux object for handling menu
 */
const LanguageSelection = ({ setLanguage, setOpen, title }) => {
  const handleClick = event => {
    setLanguage(locales[event.target.id])

    // TODO: remove later on
    if (locales[event.target.id].enable) {
      navigate(`/${locales[event.target.id].path}/start`)
    }
  }

  useEffect(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <SectionContainer>
      <SectionContainerInner>
        <SectionTitle>
          <Title>{title}</Title>
        </SectionTitle>
        <CountryWrapper>
          <CountryContainer>
            <CountryList handleClick={handleClick} />
          </CountryContainer>
        </CountryWrapper>
      </SectionContainerInner>
    </SectionContainer>
  )
}

LanguageSelection.propTypes = {
  setLanguage: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  menu: PropTypes.object,
}

/**
 *
 * Flag grid in index view
 * Triggers callback to perent once klicked
 *
 * @param {Function} handleClick callback function for handle click on flag
 */
const CountryList = ({ handleClick }) => (
  <>
    <FlexContainer>
      <CountryFlagContainer
        to={locales["ca"].enable ? `/${locales["ca"].path}/start` : "/"}
      >
        <CountryFlag
          id="ca"
          onClick={handleClick}
          src={ca}
          alt="canada flag"
          loading="lazy"
        />
      </CountryFlagContainer>
      <CountryNameContainer>
        <CountryName>Canada</CountryName>
      </CountryNameContainer>
    </FlexContainer>

    <FlexContainer>
      <CountryFlagContainer
        to={locales["se"].enable ? `/${locales["se"].path}/start` : "/"}
        isGray={true}
      >
        <CountryFlag
          isGray={true}
          id="se"
          onClick={handleClick}
          src={se}
          alt="sweden flag"
          loading="lazy"
        />
      </CountryFlagContainer>
      <CountryNameContainer>
        <CountryName>Sverige</CountryName>
      </CountryNameContainer>
    </FlexContainer>

    <FlexContainer>
      <CountryFlagContainer
        to={locales["uk"].enable ? `/${locales["uk"].path}/start` : "/"}
        isGray={true}
      >
        <CountryFlag
          isGray={true}
          id="uk"
          onClick={handleClick}
          src={gb}
          alt="united kingdom flag"
          loading="lazy"
        />
      </CountryFlagContainer>
      <CountryNameContainer>
        <CountryName>United Kingdom</CountryName>
      </CountryNameContainer>
    </FlexContainer>

    <FlexContainer>
      <CountryFlagContainer
        to={locales["nz"].enable ? `/${locales["nz"].path}/start` : "/"}
        isGray={true}
      >
        <CountryFlag
          id="nz"
          isGray={true}
          onClick={handleClick}
          src={nz}
          alt="new zealand flag"
          loading="lazy"
        />
      </CountryFlagContainer>
      <CountryNameContainer>
        <CountryName>New Zealand</CountryName>
      </CountryNameContainer>
    </FlexContainer>

    <FlexContainer>
      <CountryFlagContainer
        to={locales["au"].enable ? `/${locales["au"].path}/start` : "/"}
        isGray={true}
      >
        <CountryFlag
          isGray={true}
          id="au"
          onClick={handleClick}
          src={au}
          alt="australia flag"
          loading="lazy"
        />
      </CountryFlagContainer>
      <CountryNameContainer>
        <CountryName>Australia</CountryName>
      </CountryNameContainer>
    </FlexContainer>

    <FlexContainer>
      <CountryFlagContainer
        to={locales["us"].enable ? `/${locales["us"].path}/start` : "/"}
        isGray={true}
      >
        <CountryFlag
          id="us"
          onClick={handleClick}
          src={us}
          alt="united states of america flag"
          loading="lazy"
        />
      </CountryFlagContainer>
      <CountryNameContainer>
        <CountryName>United States</CountryName>
      </CountryNameContainer>
    </FlexContainer>
  </>
)

CountryList.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return { language: state.language }
}

const mapDispatchToProps = { setLanguage, setOpen }

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelection)
